import { ERROR_CODE, LOG_LEVEL, NOTIFICATION_TYPE, getConfig, sendNotification } from '@amc-technology/davinci-api';

import { Contact } from '@salesforce/scv-connector-base';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { RemoveParticipantVariant } from '../Model/RemoveParticipantVariant';
import { VoiceCallRecordCreationEnum } from '../Model/VoiceCallRecordCreationEnum';

/**
 * This service is used to handle the configuration from Creators Studio
 *
 * @export
 * @class ConfigService
 */

@Injectable({
  providedIn: 'root'
})
export class StudioConfigService {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  private root: ByotIAppConfigurationVariables = {
    PhoneNumberFormat: new Map([]),
    ClickToDialPhoneReformatMap: new Map([]),
  };

  phoneNumberFormatMap: Map<string, string>;
  clickToDialPhoneReformatMap: Map<string, string>;

  private callControls: ByotIAppConfigurationVariables = {
    eventTimeout: 5000,
    rejectEnabled: true,
    addCallerEnabled: true,
    conferenceEnabled: true,
    holdEnabled: true,
    swapEnabled: true,
    muteEnabled: true,
    removeParticipantVariant: 'ALWAYS',
    queueWaitTime: true,
    keypadEnabled: true
  };

  eventTimeout: number;
  rejectEnabled: boolean;
  addCallerEnabled: boolean;
  conferenceEnabled: boolean;
  holdEnabled: boolean;
  swapEnabled: boolean;
  muteEnabled: boolean;
  removeParticipantVariant: RemoveParticipantVariant;
  keypadEnabled: boolean;

  private presenceMapping: ByotIAppConfigurationVariables = {
    presenceChangeTimeout: 5000,
    channelToSalesforce: new Map([]),
    salesforceToChannel: new Map([]),
  };

  presenceChangeTimeout: number;
  channelToSalesforceMap: Map<string, string>;
  salesforceToChannelMap: Map<string, string>;

  private transcription: ByotIAppConfigurationVariables = {
    VoiceCallRecordCreation: VoiceCallRecordCreationEnum.ClientSideCallCreation,
    VoiceCallRecordToCAD: new Map([]),
    VendorCallKeyCAD: '',
    TelephonyProviderName: 'DaVinciSample',
    CommunicationServiceUrl: '', // TODO: Determine the correct default value
    RSAPrivateKey: '', // TODO: Determine the correct default value
    AuthToken: '' // TODO: Determine the correct default value
  };

  VoiceCallRecordCreation: VoiceCallRecordCreationEnum;
  VoiceCallRecordToCAD: Map<string, string>;
  VendorCallKeyCAD: string;
  telephonyProviderName: string;
  communicationServiceUrl: string;
  rsaPrivateKey: string;
  authToken: string;

  private queues: ByotIAppConfigurationVariables = {
    ctiIdToSalesforceId: new Map([]),
    queueWaitTime: true
  };

  ctiIdToSalesforceIdMap: Map<string, string>;
  queueWaitTime: boolean;

  private contacts: ByotIAppConfiguration = {
    variables: {} as ByotIAppConfigurationVariables,
    'Queue 1': {
      variables: {
        id: 'id1',
        type: 'Queue',
        phoneNumber: undefined,
        prefix: undefined,
        extension: undefined,
        endpointARN: undefined,
        queue: 'id1',
        availability: undefined,
        recordId: undefined,
        description: undefined,
        queueWaitTime: undefined
      } as ByotIAppConfigurationVariables
    } as ByotIAppConfiguration,
    'Queue 2': {
      variables: {
        id: 'id2',
        type: 'Queue',
        phoneNumber: undefined,
        prefix: undefined,
        extension: undefined,
        endpointARN: undefined,
        queue: 'id2',
        availability: undefined,
        recordId: undefined,
        description: undefined,
        queueWaitTime: undefined
      } as ByotIAppConfigurationVariables
    } as ByotIAppConfiguration
  };

  contactList: Contact[] = [];

  private loginConfig: ByotIAppConfigurationVariables = {
    MaxAttempts: 10,
    AttemptFrequencyMs: 10000
  };

  maxLoginAttempts: number;
  loginAttemptFrequencyMs: number;

  /**
   * This is the default configuration for the app. Any configurations in Creators Studio will override these values if they are valid.
   * @type {ByotIAppConfiguration}
   * @memberof StudioConfigService
   */
  private studioConfig: ByotIAppConfiguration = {
    variables: this.root,
    CallControls: this.callControls,
    PresenceMapping: this.presenceMapping,
    Transcription: this.transcription,
    Queues: this.queues,
    Contacts: this.contacts,
    Login: this.loginConfig
  };

  constructor(private loggerService: LoggerService) {
    this.log = this.loggerService.log;
  }

  async getStudioConfig(): Promise<any> {
    console.log('getStudioConfig');
    const functionName = 'getStudioConfig';
    try {
      this.log(LOG_LEVEL.Information, functionName, 'Getting Studio Config');
      const config = await getConfig();
      this.log(LOG_LEVEL.Debug, functionName, 'Studio Config', config);
      this.setConfigs(config);
      this.log(LOG_LEVEL.Information, functionName, 'Getting Studio Config');
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error getting Studio Config', error);
    }
  }

  private setConfigs(config: ByotIAppConfiguration): void {
    const functionName = 'setConfigs';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Updating Client Configs with values from Studio');
      this.setRootConfigs(config.variables);
      this.setCallControlConfigs(config.CallControls?.variables as ByotIAppConfigurationVariables);
      this.setpresenceMappings(config.PresenceMapping?.variables as ByotIAppConfigurationVariables);
      this.setTranscriptionConfigs(config.Transcription?.variables as ByotIAppConfigurationVariables);
      this.setQueueConfigs(config.Queues?.variables as ByotIAppConfigurationVariables);
      this.setContactConfigs(config.Contacts as ByotIAppConfiguration);
      this.setLoginConfigs(config.Login?.variables as ByotIAppConfigurationVariables);
      this.updateStudioConfig();
      this.log(LOG_LEVEL.Debug, functionName, 'Updated Client Config', this.studioConfig);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Studio Config', error);
    }
  }

  private setRootConfigs(config: ByotIAppConfigurationVariables): void {
    const functionName = 'setRootConfigs';
    try {
      if (!config) {
        throw new Error('Missing Root Configs');
      }
      this.log(LOG_LEVEL.Debug, functionName, 'Checking Root Configs');
      this.phoneNumberFormatMap = this.updateConfigs('PhoneNumberFormat', this.root.PhoneNumberFormat, config.PhoneNumberFormat);
      this.clickToDialPhoneReformatMap = this.updateConfigs('ClickToDialPhoneReformatMap', this.root.ClickToDialPhoneReformatMap, config.ClickToDialPhoneReformatMap);
      this.log(LOG_LEVEL.Trace, functionName, 'Root Config', this.root);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Root Config', error);
    }
  }

  private setCallControlConfigs(config: ByotIAppConfigurationVariables): void {
    const functionName = 'setCallControlConfigs';
    try {
      if (!config) {
        throw new Error('Missing CallControls Configs');
      }
      this.log(LOG_LEVEL.Debug, functionName, 'Checking Call Control Configs');
      this.eventTimeout = this.updateConfigs('eventTimeout', this.callControls.eventTimeout, config.eventTimeout);
      this.rejectEnabled = this.updateConfigs('rejectEnabled', this.callControls.rejectEnabled, config.rejectEnabled);
      this.addCallerEnabled = this.updateConfigs('addCallerEnabled', this.callControls.addCallerEnabled, config.addCallerEnabled);
      this.conferenceEnabled = this.updateConfigs('conferenceEnabled', this.callControls.conferenceEnabled, config.conferenceEnabled);
      this.holdEnabled = this.updateConfigs('holdEnabled', this.callControls.holdEnabled, config.holdEnabled);
      this.swapEnabled = this.updateConfigs('swapEnabled', this.callControls.swapEnabled, config.swapEnabled);
      this.muteEnabled = this.updateConfigs('muteEnabled', this.callControls.muteEnabled, config.muteEnabled);
      this.removeParticipantVariant = this.updateConfigs('removeParticipantVariant', this.callControls.removeParticipantVariant, config.removeParticipantVariant);
      this.keypadEnabled = this.updateConfigs('keypadEnabled', this.callControls.keypadEnabled, config.keypadEnabled);
      this.log(LOG_LEVEL.Trace, functionName, 'Call Control Config', this.callControls);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Call Control Config', error);
    }
  }

  private setpresenceMappings(config: ByotIAppConfigurationVariables): void {
    const functionName = 'setpresenceMappings';
    try {
      if (!config) {
        throw new Error('Missing PresenceMapping Configs');
      }
      this.log(LOG_LEVEL.Debug, functionName, 'Checking Presence Mapping Configs');
      this.presenceChangeTimeout = this.updateConfigs('presenceChangeTimeout', this.presenceMapping.presenceChangeTimeout, config.presenceChangeTimeout);
      this.channelToSalesforceMap = this.updateConfigs('channelToSalesforce', this.presenceMapping.channelToSalesforce, config.channelToSalesforce);
      this.salesforceToChannelMap = this.updateConfigs('salesforceToChannel', this.presenceMapping.salesforceToChannel, config.salesforceToChannel);
      this.log(LOG_LEVEL.Trace, functionName, 'Presence Mapping Config', this.presenceMapping);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Presence Mapping Config', error);
    }
  }

  private setTranscriptionConfigs(config: ByotIAppConfigurationVariables): void {
    const functionName = 'setTranscriptionConfigs';
    try {
      if (!config) {
        throw new Error('Missing Transcription Configs');
      }
      this.log(LOG_LEVEL.Debug, functionName, 'Checking Transcription Configs');
      this.VoiceCallRecordCreation = this.updateVoiceCallRecordCreationConfig('VoiceCallRecordCreation', this.transcription.VoiceCallRecordCreation, config.VoiceCallRecordCreation);
      this.VoiceCallRecordToCAD = this.updateConfigs('VoiceCallRecordToCAD', this.transcription.VoiceCallRecordToCAD, config.VoiceCallRecordToCAD);
      this.VendorCallKeyCAD = this.updateConfigs('VendorCallKeyCAD', this.transcription.VendorCallKeyCAD, config.VendorCallKeyCAD, true);
      this.telephonyProviderName = this.updateConfigs('TelephonyProviderName', this.transcription.TelephonyProviderName, config.TelephonyProviderName, true);
      this.communicationServiceUrl = this.updateConfigs('CommunicationServiceUrl', this.transcription.CommunicationServiceUrl, config.CommunicationServiceUrl, true);
      this.rsaPrivateKey = this.updateConfigs('RSAPrivateKey', this.transcription.RSAPrivateKey, config.RSAPrivateKey, true, true);
      this.authToken = this.updateConfigs('AuthToken', this.transcription.AuthToken, config.AuthToken, true, true);
      this.log(LOG_LEVEL.Trace, functionName, 'Transcription Config', this.presenceMapping);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Transcription Config', error);
    }
  }

  private setQueueConfigs(config: ByotIAppConfigurationVariables): void {
    const functionName = 'setQueueConfigs';
    try {
      if (!config) {
        throw new Error('Missing Queue Configs');
      }
      this.log(LOG_LEVEL.Debug, functionName, 'Checking Queue Configs');
      this.ctiIdToSalesforceIdMap = this.updateConfigs('ctiIdToSalesforceId', this.queues.ctiIdToSalesforceId, config.ctiIdToSalesforceId);
      this.queueWaitTime = this.updateConfigs('SalesforceQueueWaitTime', this.queues.queueWaitTime, config.SalesforceQueueWaitTime);
      this.log(LOG_LEVEL.Trace, functionName, 'Queue Config', this.queues);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Queue Config', error);
    }
  }

  private setContactConfigs(config: ByotIAppConfiguration): void {
    const functionName = 'setContactConfigs';
    try {
      if (!config) {
        throw new Error('Missing Contact Configs');
      }
      let contactList = config;
      this.log(LOG_LEVEL.Debug, functionName, 'Checking Contact Configs');
      if (Object.keys(config).length < 2) {
        this.log(LOG_LEVEL.Warning, functionName, 'No Contacts Configured, using default', this.contacts);
        contactList = this.contacts;
      }
      for (const key in contactList) {
        if (contactList.hasOwnProperty(key) && key !== 'variables') {
          const contact: Contact = {
            name: key,
            id: contactList[key].variables['id'] != null ? contactList[key].variables['id'] : undefined,
            type: contactList[key].variables['type'] != null ? contactList[key].variables['type'] : undefined,
            phoneNumber: contactList[key].variables['phoneNumber'] != null ? contactList[key].variables['phoneNumber'] : undefined,
            prefix: contactList[key].variables['prefix'] != null ? contactList[key].variables['prefix'] : undefined,
            extension: contactList[key].variables['extension'] != null ? contactList[key].variables['extension'] : undefined,
            endpointARN: contactList[key].variables['endpointARN'] != null ? contactList[key].variables['endpointARN'] : undefined,
            queue: contactList[key].variables['queue'] != null ? contactList[key].variables['queue'] : undefined,
            availability: contactList[key].variables['availability'] != null ? contactList[key].variables['availability'] : undefined,
            recordId: contactList[key].variables['recordId'] != null ? contactList[key].variables['recordId'] : undefined,
            description: contactList[key].variables['description'] != null ? contactList[key].variables['description'] : undefined,
            queueWaitTime: contactList[key].variables['queueWaitTime'] != null ? contactList[key].variables['queueWaitTime'] : undefined
          };
          this.log(LOG_LEVEL.Loop, functionName, 'Adding contact to list', contact);
          this.contactList.push(contact);
        }
      }
      this.log(LOG_LEVEL.Trace, functionName, 'Contact Config', this.contactList);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Contact Config', error);
    }
  }

  private setLoginConfigs(config: ByotIAppConfigurationVariables): void {
    const functionName = 'setLoginConfigs';
    try {
      if (!config) {
        this.log(LOG_LEVEL.Error, functionName, 'Error setting Login Config');
      }

      this.maxLoginAttempts = this.updateConfigs('MaxAttempts', this.loginConfig.MaxAttempts, config?.MaxAttempts);
      this.loginAttemptFrequencyMs = this.updateConfigs('AttemptFrequencyMs', this.loginConfig.AttemptFrequencyMs, config?.AttemptFrequencyMs);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting Login Config', error);
    }
  }

  private updateStudioConfig(): void {
    const functionName = 'updateStudioConfig';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Updating Studio Config' );
      this.studioConfig.variables = this.root;
      this.studioConfig.CallControls = this.callControls;
      this.studioConfig.PresenceMapping = this.presenceMapping;
      this.studioConfig.Transcription = this.transcription;
      this.studioConfig.Queues = this.queues;
      this.studioConfig.Login = this.loginConfig;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error updating Studio Config', error);
    }
  }

/**
 * this function will compare the values of the default config and the studio config and return the correct value
 *
 * @private
 * @param {*} defaultConfig
 * @param {*} studioConfig
 * @return {*}  {void}
 * @memberof StudioConfigService
 */
private updateConfigs(configName: string, defaultConfig: any, studioConfig: any, critical: boolean = false, pii: boolean = false): any {
    const functionName = 'updateConfigs';
    try {
      this.log(LOG_LEVEL.Loop, functionName, 'Comparing Configs', { configName });
      if (studioConfig != null && typeof defaultConfig === typeof studioConfig) {
        this.log(LOG_LEVEL.Loop, functionName, 'Valid Studio Config', pii ? { configName } : { configName, studioConfig });
        return studioConfig;
      } else {
        this.log(critical ? LOG_LEVEL.Critical : LOG_LEVEL.Error, functionName, 'Invalid Studio Config, using Default Config', pii ? { configName, defaultConfig } : { configName, defaultConfig, studioConfig });
        if (critical) {
          sendNotification('Configuration Error. Please contact your administrator for assistance.', NOTIFICATION_TYPE.Error);
        }
        return defaultConfig;
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error comparing Configs', error);
    }
  }

  private updateVoiceCallRecordCreationConfig(configName: string, defaultConfig: any, studioConfig: any): VoiceCallRecordCreationEnum {
    const functionName = 'updateVoiceCallRecordCreationConfig';

    try {
      this.log(LOG_LEVEL.Loop, functionName, 'Comparing Configs', { configName });


      //  Return the value of the studioConfig to the VoiceCallRecordCreationConfig enum type or if the value specified does not match one of the enum types, return the default value.
      if (studioConfig != null && typeof defaultConfig === typeof VoiceCallRecordCreationEnum[studioConfig]) {
        this.log(LOG_LEVEL.Loop, functionName, 'Valid Studio Config', { configName, studioConfig });
        return VoiceCallRecordCreationEnum[studioConfig as keyof typeof VoiceCallRecordCreationEnum];
      } else {
        this.log(LOG_LEVEL.Error, functionName, 'Invalid Studio Config, using Default Config', { configName, defaultConfig, studioConfig });
        return defaultConfig;
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error comparing Configs', error);
    }
  }
}

// TODO: These are based on the IAppConfiguration from the DaVinci API. We should update the DaVinci API to include additional types for the variables.
export interface ByotIAppConfiguration {
  variables: ByotIAppConfigurationVariables;
  [child: string]: ByotIAppConfiguration | ByotIAppConfigurationVariables;
}

export interface ByotIAppConfigurationVariables {
  [key: string]: boolean | number | string | string[] | Map<string, string> | VoiceCallRecordCreationEnum;
}
