import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { ByotComponent } from './components/byot/byot.component';
import { CommunicationService } from './services/communication.service';
import { ConfigurationService } from './services/configuration.service';
import { FormsModule } from '@angular/forms';
import { HomeBYOTComponent } from './components/home/home-byot.component';
import { HttpClientModule } from '@angular/common/http';
import { LoggerService } from './services/logger.service';
import { RouterModule } from '@angular/router';
import { StorageService } from './services/storage.service';
import { StudioConfigService } from './services/studio-config.service';

@NgModule({
  declarations: [ByotComponent, HomeBYOTComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeBYOTComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (configService: ConfigurationService, loggerService: LoggerService, studioConfigService: StudioConfigService) =>
        async () => {
          await configService.loadConfigurationData();
          await loggerService.initialize();
          await studioConfigService.getStudioConfig();
        },
      deps: [ConfigurationService, LoggerService, StudioConfigService],
      multi: true
    },
    LoggerService,
    StorageService,
    StudioConfigService,
    CommunicationService
  ],
  bootstrap: [ByotComponent]
})
export class AppModule {}
