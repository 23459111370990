import { ERROR_CODE, IInteraction, LOG_LEVEL } from '@amc-technology/davinci-api';

import { ISoftPhoneState } from '../Model/ISoftPhoneState';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { Subscription } from 'rxjs';
import bind from 'bind-decorator';

@Injectable()
export class StorageService {
  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void;

  private interactionList: Map<string, IInteraction> = new Map([]);
  private voiceCallIdToInteractionMap: Map<string, string> = new Map([]);

  stateSubscriber: Subscription;
  interactionToVoiceCallRecordDetailsMap: Map<string, {
    voiceCallId: string,
    startTime: Date
  }> = new Map([]);

  constructor(private loggerService: LoggerService) {
    this.log = loggerService.log;
    this.syncWithLocalStorage();
  }

  public updateInteractionList(interactionList: Map<string, IInteraction>) {
    const functionName = 'updateInteractionList';
    try {
      this.interactionList = interactionList;
      this.storeToLocalStorage();
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error updating interactionList', error);
    }
  }

  public updateVoiceCallIdToInteractionMap(voiceCallIdToInteractionMap: Map<string, string>) {
    const functionName = 'updateVoiceCallIdToInteractionMap';
    try {
      this.voiceCallIdToInteractionMap = voiceCallIdToInteractionMap;
      this.storeToLocalStorage();
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error updating voiceCallIdToInteractionMap', error);
    }
  }

  public storeToLocalStorage() {
    const functionName = 'storeToLocalStorage';
    try {
      localStorage.setItem('interactionList', JSON.stringify(Array.from(this.interactionList.entries())));
      localStorage.setItem('voiceCallIdToInteractionMap', JSON.stringify(Array.from(this.voiceCallIdToInteractionMap.entries())));
    } catch (error) {
     this.log(LOG_LEVEL.Error, functionName, 'Error storing to local storage', error);
    }
  }

  private syncWithLocalStorage() {
    try {
      this.interactionList = new Map(JSON.parse(localStorage.getItem('interactionList')));
      this.voiceCallIdToInteractionMap = new Map(JSON.parse(localStorage.getItem('voiceCallIdToInteractionMap')));
    } catch (error) {
      this.log(LOG_LEVEL.Error, 'syncWithLocalStorage', 'Error syncing with local storage', error);
    }
  }

  getCurrentState() {
    const functionName = 'getCurrentState';
    try {
      const state = JSON.parse(localStorage.getItem('state'));
      this.log(LOG_LEVEL.Loop, functionName, 'Current state', state);
      return state;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error getting current state', error);
    }
  }

  @bind
  setState(state: ISoftPhoneState) {
    const functionName = 'setState';
    try {
      this.log(LOG_LEVEL.Loop, functionName, 'Setting state', state);
      localStorage.setItem('state', JSON.stringify(state));
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error setting state', error);
    }
  }
}
