export enum PROMISE_EVENT_TYPE {
  accepted = 'accepted',
  rejected = 'rejected',
  hangup = 'hangup',
  outbound = 'outbound',
  connected = 'connected',
  disconnected = 'disconnected',
  held = 'held',
  unheld = 'unheld',
  muted = 'muted',
  unmuted = 'unmuted',
  presence = 'presence',
  addParticipant = 'addParticipant',
  removeParticipant = 'removeParticipant',
  warmTransferred = 'warmTransferred',
  blindTransferred = 'blindTransferred',
  conference = 'conference',
  logout = 'logout',
  all = 'all'
}
