export class DeferredPromise {
  promise: Promise<any>;
  resolve: any;
  reject: any;
  timeCreated: number;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
    this.timeCreated = Date.now();
  }
}
