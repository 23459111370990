import { IInteraction } from '@amc-technology/davinci-api';
import {
  PhoneCall,
  PhoneCallAttributes,
  Constants,
  Contact
} from '@salesforce/scv-connector-base';

export class Call extends PhoneCall {
  interactions: IInteraction[];
  /**
   * Create a Call.
   * @param {string} callType - Outbound, Inbound or Transfer
   * @param {Contact} contact - Contact associated with this Call
   * @param {string} callAttributes - call attributes
   * @param {string} callInfo - call info
   * @param {IInteraction[]} interactions - amc interaction object associated with this call
   */
  constructor(
    callType: string,
    contact: Contact,
    callAttributes: any,
    callInfo: any,
    callId: string,
    interactions: IInteraction[]
  ) {
    const state = Constants.CALL_STATE.RINGING;
    callAttributes.initialCallHasEnded = false;
    callAttributes.state = state;
    callAttributes.isOnHold = callInfo && callInfo.isOnHold;
    callId = callId || Math.random().toString(36).substring(7);
    if (callAttributes.participantType === Constants.PARTICIPANT_TYPE.INITIAL_CALLER) {
      callInfo.parentCallId = callId;
    }
    super({
      callId,
      callType,
      contact,
      state,
      callAttributes,
      phoneNumber: contact && contact.phoneNumber,
      callInfo
    });
    this.interactions = interactions;
  }

  /**
   * set callId of parent call
   */
  set parentCallId(parentCallId) {
    this.callInfo['parentCallId'] = parentCallId;
  }
}
