import { ERROR_CODE, IInteraction, LOG_LEVEL, USER_TYPES } from '@amc-technology/davinci-api';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { SENDER_TYPE } from '../Model/SenderType';
import { StudioConfigService } from './studio-config.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private orgId: string; // SAlesforce Organization Id
  private orgUrl: string; // Salesforce Organization Url
  private callCenterApiName: string; // DaVinci Call Contact Center name in Salesforce
  private crmId: string; // Salesforce User Id
  private interactionMessageIdMap: Map<string, string[]> = new Map<string, string[]>(); // Map of interactionId to messageId

  log: (logLevel: LOG_LEVEL, fName: string, message: string, object?: any, errorCode?: ERROR_CODE, localTime?: Date) => void = () => {};

  // private log: (functionName: string, messages: any[]) => void = () => {};
  constructor(private loggerService: LoggerService, private scs: StudioConfigService, private http: HttpClient) {
    this.log = this.loggerService.log;
  }

  /**
   * This function initializes the communication service with the required parameters.
   *
   * @param {string} orgId The Salesforce Organization Id from Salesforce
   * @param {string} orgUrl The Salesforce Organization Url from Salesforce
   * @param {string} callCenterApiName The Salesforce Call Center API Name from Salesforce
   * @param {string} crmId The Salesforce User Id from Salesforce
   * @memberof CommunicationService
   */
  async initialize(orgId: string, orgUrl: string, callCenterApiName: string, crmId: string) {
    const functionName = 'initialize';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Initializing communication service with data from Salesforce Org', { orgId, orgUrl, callCenterApiName, crmId });
      this.orgId = orgId;
      this.orgUrl = orgUrl;
      this.callCenterApiName = callCenterApiName;
      this.crmId = crmId;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error initializing communication service', error);
    }
  }

  /**
   * This function creates a VoiceCall Record in Salesforce via the communication service and returns the VoiceCallId.
   * If the call already exists, it will return the existing VoiceCallId.
   *
   * @param {IInteraction} interaction from CTI
   * @param {string} interaction.interactionId The interactionId from CTI
   * @param {string} interaction.details.fields.DialedPhone.Value' The DNIS from CTI
   * @param {string} interaction.details.fields.Phone.Value The ANI from CTI
   * @return {*} {Promise<string>} containing VoiceCallId needed for ScreenPop
   * @memberof CommunicationService
   */
  async createCall(interaction: IInteraction, startTime: Date, vendorCallKey?: string): Promise<void> {
    const functionName = 'createCall';

    try {

      const to = interaction.details.fields.DialedPhone.Value;
      const from = interaction.details.fields.Phone.Value;
      this.log(LOG_LEVEL.Information, functionName, 'Creating call via communication service', { interactionId: interaction.interactionId, vendorCallKey, to, from });
      startTime = startTime || new Date();
      const payload = {
        'to': to,
        'from': from,
        'vendorCallKey': vendorCallKey ?? this.formatInteractionId(interaction.interactionId),
        'startTime': startTime,
        'participants': [
          {
            'participantKey': from,
            'type': 'END_USER'
          }
        ],
        'requestContext': {
          'orgId': this.orgId,
          'orgUrl': this.orgUrl,
          'callCenterApiName': this.callCenterApiName,
          'telephonyProviderName': this.scs.telephonyProviderName,
          'rsaPrivateKey': this.scs.rsaPrivateKey
        }
      };
      const callAttributes = this.processVoiceCallRecordToCADMapping(interaction);

      if (callAttributes) {
        payload['callAttributes'] = callAttributes;
      }

      this.log(LOG_LEVEL.Information, functionName, 'Creating call via communication service', { interactionId: interaction.interactionId, vendorCallKey, to, from });

      const response = await this.http.post<{voiceCallId: string, errors: any}>(this.scs.communicationServiceUrl + '/api/callevent/CreateInbound', payload,
      {
        headers: {
          'AuthToken': this.scs.authToken
        }
      }).toPromise();

      if (response.errors?.length > 0) {
        this.log(LOG_LEVEL.Error, functionName, 'Errors were returned by Salesforce when the DaVinci communication service attempted to create the Voice Call Record.', response.errors);
      }

      if (response.voiceCallId) {
        this.log(LOG_LEVEL.Debug, functionName, 'The Voice Call Record was successfully created by the DaVinci Communication Service.', response.voiceCallId);
        this.interactionMessageIdMap.set(interaction.interactionId, []);
        return Promise.resolve();
      } else {
        this.log(LOG_LEVEL.Error, functionName, 'Error creating call via communication service. No valid VoiceCallId was received back from DaVinci Communication Service.', response);
        return Promise.reject();
      }
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error creating call', error);
      return Promise.reject(error);
    }
  }

  /**
   * This function updates an existing VoiceCall Record in Salesforce via the communication service and returns the the status of the update.
   *
   * @return {*} {Promise<string>} containing status of the update process
   * @memberof CommunicationService
  */
  async updateCall(data: {voiceCallId: string, startTime: Date, endTime: Date }, vendorCallKey?: string): Promise<string> {
    const functionName = 'updateCall';
    try {
      this.log(LOG_LEVEL.Information, functionName,
        'Updating call via communication service', { voiceCallId: data.voiceCallId });

      const payload: any = data;

      payload.requestContext = {
        orgId: this.orgId,
        orgUrl: this.orgUrl,
        callCenterApiName: this.callCenterApiName,
        telephonyProviderName: this.scs.telephonyProviderName,
        rsaPrivateKey: this.scs.rsaPrivateKey
      };

      const response = await this.http.patch<{status: string}>(`${this.scs.communicationServiceUrl}/api/callevent/Update`, payload,
      {
        headers: {
          'AuthToken': this.scs.authToken
        }
      }).toPromise();

      this.log(LOG_LEVEL.Debug, functionName, 'Response from communication service', response);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error updating call', error);
      return Promise.reject(error);
    }
  }

  /**
   * This function registers a vendorCallKey (UCID) and the current users AgentId with the communication service.
   *
   * @return {*} {Promise<string>} containing status of the registration process
   * @memberof CommunicationService
  */
  async registerUCIDAndAgentId(vendorCallKey: string): Promise<string> {
    const functionName = 'registerUCIDAndAgentId';
    try {
      this.log(LOG_LEVEL.Information, functionName,
        'Registering UCID and AgentId via communication service', { vendorCallKey, agentId: this.crmId });

      const payload = {
        ucid: vendorCallKey,
        agentId: this.crmId ?? null,
      };

      const response = await this.http.post<{status: string}>(`${this.scs.communicationServiceUrl}/api/agent/RegisterAgentId`, payload,
      {
        headers: {
          'AuthToken': this.scs.authToken
        }
      }).toPromise();

      this.log(LOG_LEVEL.Debug, functionName, 'Response from communication service', response);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error registering UCID and AgentId', error);
      return Promise.reject(error);
    }
  }

   /**
   * This function makes a POST request to the BYOT Comm. Service to deregister a specified AgentId for the given vendorCallKey (UCID).
   *
   * @return {*} {Promise<string>} containing status of the registration process
   * @memberof CommunicationService
  */
  async deregisterAgentIdForUCID(vendorCallKey: string): Promise<void> {
    const functionName = 'deregisterAgentIdForUCID';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'DeregisterAgentId for a given UCID via communication service', { vendorCallKey, agentId: this.crmId });

      const payload = {
        ucid: vendorCallKey,
        agentId: this.crmId ?? null
      };

      const response = await this.http.post<{response: any}>(this.scs.communicationServiceUrl + '/api/agent/DeregisterAgentId', payload,
      {
        headers: {
          'AuthToken': this.scs.authToken
        }
      }).toPromise();

      this.log(LOG_LEVEL.Debug, functionName, 'Response from communication service', response);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error when attempting to end the transcription for the specified UCID', error);
      return Promise.reject(error);
    }
  }

  /**
   * This function appends a message to the existing transcript in Salesforce via the communication service.
   * The message will not be displayed unless an agent has teh VoiceCall record open for the corresponding call.
   * The messages will not persist if the VoiceCall record is closed because the call was disconnected.
   * TODO: Add a way to persist the transcript after the call is completed
   *
   * @param {IInteraction} interaction from CTI
   * @param {string} interaction.interactionId The interactionId from CTI
   * @param {string} interaction.transcripts.data The message to be appended to the transcript
   * @param {string} interaction.transcripts.id The messageId from the transcription service
   * @param {string} interaction.transcripts.timestamp The timestamp of the message
   * @param {USER_TYPES} interaction.transcripts.context.userType The userType that is sending the message in the transcript
   * @return {*}  {Promise<void>}
   * @memberof CommunicationService
   */
  async sendTranscription(interaction: IInteraction, vendorCallKey?: string): Promise<void> {
    const functionName = 'sendTranscription';
    try {
      this.log(LOG_LEVEL.Debug, functionName, 'Sending transcription via communication service', { interactionId: interaction.interactionId, messageId: interaction.transcripts.id, vendorCallKey });
      this.log(LOG_LEVEL.Trace, functionName, 'Detailed Transcription information', {
        interactionId: interaction.interactionId,
        messageId: interaction.transcripts.id,
        startTime: interaction.transcripts.timestamp,
        userType: interaction.transcripts.context.userType
      });

      if (this.interactionMessageIdMap.has(interaction.interactionId) === false) {
        this.log(LOG_LEVEL.Trace, functionName, 'No message map for interaction, VoiceCall must have been made via client side api.', { interactionId: interaction.interactionId });
        this.interactionMessageIdMap.set(interaction.interactionId, []);
      }

      if (this.interactionMessageIdMap.get(interaction.interactionId)?.includes(interaction.transcripts.id)) {
        this.log(LOG_LEVEL.Trace, functionName, 'Message already sent', { interactionId: interaction.interactionId, messageId: interaction.transcripts.id });
        return;
      }

      const formattedId = vendorCallKey ?? this.formatInteractionId(interaction.interactionId);
      const isAgent = interaction.transcripts.context.userType === USER_TYPES.Agent;
      const response = await this.http.post<{response: any}>(this.scs.communicationServiceUrl + '/api/transcription/CreateTranscript', {
        'content': interaction.transcripts.data,
        'messageId': interaction.transcripts.id,
        'participantId': isAgent ? this.crmId : `${formattedId}${SENDER_TYPE.END_USER}`,
        'senderType': isAgent ? SENDER_TYPE.HUMAN_AGENT : SENDER_TYPE.END_USER,
        'startTime': interaction.transcripts.timestamp,
        'endTime': new Date(),
        'vendorCallKey': formattedId,
        'requestContext': {
          'orgId': this.orgId,
          'orgUrl': this.orgUrl,
          'callCenterApiName': this.callCenterApiName,
          'telephonyProviderName': this.scs.telephonyProviderName,
          'rsaPrivateKey': this.scs.rsaPrivateKey
        }
      },
      {
        headers: {
          'AuthToken': this.scs.authToken
        }
      }).toPromise();

      this.log(LOG_LEVEL.Debug, functionName, 'Response from communication service', response);
      this.interactionMessageIdMap.set(interaction.interactionId, [...this.interactionMessageIdMap.get(interaction.interactionId), interaction.transcripts.id]);
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error sending transcription', error);
    }
  }

  /**
   * This function will take an interactionId and format it so that it can be used in URL by Salesforce.
   *
   * @private
   * @param {string} interactionId
   * @return {*} {string} formattedId
   * @memberof CommunicationService
   */
  formatInteractionId(interactionId: string): string {
    const functionName = 'formatInteractionId';
    try {
      const formattedId = interactionId.replace(/[^a-zA-Z0-9._~-]/g, '');
      this.log(LOG_LEVEL.Loop, functionName, 'Formatted interactionId', { interactionId, formattedId });
      return formattedId;
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error formatting interactionId', { interactionId, error });
    }
  }


    /**
   * This function will take an interactionId and format it so that it can be used in URL by Salesforce.
   *
   * @private
   * @param {string} interactionId
   * @return {} {string} callAttributes
   * @memberof CommunicationService
   */
  processVoiceCallRecordToCADMapping(interaction: IInteraction): string {
    const functionName = 'processCADToVoiceCallRecord';

    try {
      this.log(LOG_LEVEL.Information, functionName, 'Processing CAD to VoiceCall Record', { interaction});

      const callAttributes = {};

      for (const [key, value] of Object.entries(this.scs.VoiceCallRecordToCAD)) {
        if (interaction.details.fields[value] != null) {
          callAttributes[key] = interaction.details.fields[value].Value;
        }
      }

      return JSON.stringify(callAttributes) ?? '';
    } catch (error) {
      this.log(LOG_LEVEL.Error, functionName, 'Error processing CAD to VoiceCall Record', error);
    }
  }
}
